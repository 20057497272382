import * as React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import dayjsEn from 'dayjs/locale/en';
import dayjsRu from 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import flatten from 'flat';
import { I18n as i18, TranslateOptions } from 'i18n-js';
// import * as I18n from 'i18n-js';
import { ru as pluralizeRu, PluralCategory } from 'make-plural';

import * as plural from './translations/plural';
import * as ui from './translations/ui';

const definedLocale = ['en', 'ru'] as const;
type DefinedLocale = typeof definedLocale[number];

// User-defined type guard function
const isLocaleDefined = (locale: any): locale is DefinedLocale => definedLocale.includes(locale);

const I18n = new i18({
    // en: {
    //   hello: "Hi!",
    // },
    // "pt-BR": {
    //   hello: "Olá!",
    // },
});

export type Day =
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';

dayjs.extend(utc);
dayjs.extend(timezone);

// I18n.fallbacks = true;
I18n.defaultLocale = 'en';

const createPluralization = (
    pluralizationFunction: (n: number | string, ord?: boolean) => PluralCategory,
) => (count: number) => [pluralizationFunction(count)];

// I18n.pluralization['ru'] = createPluralization(pluralizeRu);

const uiTranslations: Record<string, any> = ui;
const pluralTranslations: Record<string, any> = plural;

const momentLocatization: Record<string, any> = {
    ru: dayjsRu,
    en: dayjsEn,
};

const setI18nConfig = ({
    translations,
    locale,
}: {
    translations?: Record<string, object>;
    locale?: string;
}) => {
    if (translations) {
        Object.keys(translations).forEach(key => {
            if (uiTranslations[key]) {
                translations[key] = { ...translations[key], ui: uiTranslations[key] };
            }
            if (pluralTranslations[key]) {
                translations[key] = { ...translations[key], plural: pluralTranslations[key] };
            }
        });

        I18n.translations = translations;
    }

    if (locale) {
        localStorage.setItem('language', locale);
        I18n.locale = locale.replace(/-.+/, '');
        dayjs.locale(I18n.locale, momentLocatization[I18n.locale] || {});
    }
};

export const setAppropriateLocale = (locale: string) => {
    const CIS = [
        'az', // Azerbaijan
        'be', // Belarus
        'et', // Estonia
        'hy', // Armenia
        'ka', // Georgia
        'kk', // Kazakhstan
        'ky', // Kyrgyzstan
        'lt', // Lithuania
        'lv', // Latvia
        'tg', // Tajikistan
        'tk', // Turkmenistan
        'uk', // Ukraine
        'uz', // Uzbekistan
    ];

    if (isLocaleDefined(locale)) {
        return locale;
    } else if (CIS.includes(locale)) {
        return 'ru';
    } else {
        return 'en';
    }
};

// @ts-ignore
const userLocale = setAppropriateLocale((navigator.language || navigator.userLanguage) as string);

if (process.env.REACT_APP_NAME === 'webclient') {
    const locale = localStorage.getItem('language');
    if (locale) setI18nConfig({ locale });
} else {
    setI18nConfig({ locale: localStorage.getItem('language') || userLocale });
}

export function t(name: string, params: TranslateOptions = {}) {
    return I18n.t(name, params);
}

export function createTranslate(entity: string) {
    return function (name: string, params: TranslateOptions = {}) {
        return I18n.t(`${entity}.${name}`, params);
    };
}

// Upper case
export function translateUcFirst(name: string, params: TranslateOptions = {}) {
    return ucFirst(I18n.t(name, params));
}
// Lower case
export function translateLcFirst(name: string, params: TranslateOptions = {}) {
    return lcFirst(I18n.t(name, params));
}

export function ucFirst(str: string) {
    if (!str || str.length === 0) return str;
    return str[0].toUpperCase() + str.slice(1);
}

export function lcFirst(str: string) {
    if (!str || str.length === 0) return str;
    return str[0].toLowerCase() + str.slice(1);
}

export function replace(text: string, replacement: { [x: string]: string | number }) {
    Object.keys(replacement).forEach(key => {
        text = text.replace(new RegExp(`{${key}}`, 'gm'), String(replacement[key]));
    });
    return text;
}

export default I18n;

export const I18nSetConfig = setI18nConfig;

export function convertLocale(locale: string) {
    return locale.replace(/-.*/, '');
}

export function createTranslationMap(translation: object) {
    const result = flatten<object, any>(translation);

    Object.keys(result).forEach(key => {
        result[key] = key;
    });

    return flatten.unflatten<any, any>(result, { object: true });
}

export const I18nContext = React.createContext({
    locale: I18n.locale,
    setLocale: (locale: string) => setI18nConfig({ locale }),
});

export const createDayjsWithTimezone = (
    date: Dayjs | Date | string | number,
    timezone?: number,
) => {
    return timezone !== undefined ? dayjs(date).utcOffset(timezone) : dayjs(date);
};

type LocaleConfig = {
    formats: Record<string, string>;
    use12Hours: boolean;
};

const localeConfig: Record<string, LocaleConfig> = {
    en: {
        formats: {
            time: 'hh:mm A',
        },
        use12Hours: true,
    },
    ru: {
        formats: {
            time: 'HH:mm',
        },
        use12Hours: false,
    },
};

export const getLocaleConfig = (locale: string) => {
    return localeConfig[locale];
};

export const dayNumber: Record<Day, number> = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
};

export function getPlural(count: number, translations: string): string {
    return t(translations, { count });
}
