const aquamarket = {
    loginTitle: {
        phoneNumber: 'Enter your phone number',
        email: 'Enter your email',
        smsCode: 'Enter {{codeLength}}-digit code',
        info: "Let's get to know each other",
    },
    loginSubtitle: {
        phoneNumber:
            'To register in the system and make purchases\n\nAfter clicking the button, you will receive a call-reset. Do not answer the call.',
        info: "What's your name?",
        smsCode: 'Enter the last {{codeLength}} digits of the incoming call',
    },
    bonusesField: {
        title: 'Accumulated points',
        use: 'Use points',
        info: '{{maxCashbackPoints}} points',
    },
    order: {
        guide: {
            title: 'How to buy',
            step1: 'After payment, the cell will open. This may take a few seconds',
            step2: 'Take the goods',
            step3: 'Put an empty bottle in the cell',
            step4: "Don't forget to close the cell",
        },
        order: {
            total: 'To pay: ',
            mainBtn: 'Pay {{total}} {{currency}}',
            toPay: 'To pay',
            bonuses: 'Points for purchase',
            error: 'Unable to complete the payment. Please try again later.',
        },
        orderItem: {
            rent: 'rent',
            quantity: '{{quantity}} pcs',
            yourPurchase: 'Your purchase',
        },
        returnedBottlesNotification: {
            returnableContainerName: 'bottle (19 l)',
            title: 'Conditions for providing a bottle in use',
            paragraph1: 'If you do not have an exchange bottle, you can use the rental service.',
            paragraph2: 'How it works?',
            paragraph3:
                'You pay only for water, without the cost of the container. At the next purchase, leave this empty container in the cell.',
            paragraph4: 'Agreeing to the terms of the service, you agree:',
            paragraph5:
                'Purchase water in the micro market not less than 1 time in {{rentPeriod}} days',
            paragraph6:
                'Carefully handle the container. The container with cracks and foreign odors is not accepted',
            paragraph7: 'Return the empty container to the cell at each purchase.',
            paragraph8:
                'In case of refusal from the service, you will need to return the container to the company:',
            paragraph9:
                'Go to the warehouse personally and hand over the container to the specialists',
            paragraph10:
                'Pass the container to the courier when refueling the micro market. To do this, place an order for the return of the container by phone {{phone}}',
            paragraph11:
                'You can also buy it, paying the full cost of {{price}} rubles. The way of payment will be reported to you by the operator by phone.',
            acceptBtn: 'All clear',
        },
        returnedBottles: {
            title: 'Do you have an empty 19 l bottle?',
            paragraph1:
                'In this Micro Market, you can exchange an empty reusable {{returnableContainerName}} for a full one, paying only for water.',
            paragraph2: 'If you do not have an empty bottle yet, you can ',
            rentBtn: 'rent it',
            buyBtn: 'buy it',
            paragraph3: 'and in the future change it on a full one.',
            radio: {
                have: 'I have a reusable container',
                rent: 'I want to rent a container',
                buy: 'I want to buy a container',
                ok: 'Good',
                conditions: {
                    byPlacingOrder: 'By placing an order, you agree to the ',
                    freeUse: 'conditions of free use',
                    exchangeContainer: 'of the exchange container',
                },
            },
            leaveBottle: 'Don’t forget to leave an empty bottle in the cell',
        },
        safePayment: {
            text:
                'We use a secure payment form, your card details are not saved and not passed to third parties',
        },
        paymentItem: {
            deleteModal: {
                delete: 'Delete',
                card: 'card',
                paymentMethod: 'payment method',
                fromProfile: 'from profile?',
            },
            deleteCardSuccess: 'Card successfully deleted',
            deletePaymentMethodSuccess: 'Payment method successfully deleted',
            delete: 'Delete',
            cancel: 'Cancel',
            sbp: 'SBP',
        },
        status: {
            waiting: {
                title: 'A little patience',
                subtitle: 'The payment processing time is up to 1 minute',
            },
            success: {
                title: 'Thank you!',
                subtitle: 'Your purchase has been successfully paid',
                additional: 'The cell will open in one minute',
            },
            error: {
                title: 'Your payment did not go through',
                subtitle: 'This may be a temporary failure. Try again.',
            },
            support: {
                text: 'If the cell did not open, write to support or call ',
            },
        },
        unableCell: {
            title: {
                disabled: 'This cell is not available for purchase at the moment',
                inactive: 'Technical work is underway',
                error: 'An error occurred while loading the cell. Please try again later',
                empty: 'This cell is empty',
            },
            subtitle: {
                inactive:
                    'The micro market is currently unavailable, but we will fix everything soon.',
                default: 'Scan another cell with a green light.',
            },
        },
    },
};

export default aquamarket;
